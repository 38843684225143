import { render } from "./Vehicles.vue?vue&type=template&id=3e55b2ae"
import script from "./Vehicles.vue?vue&type=script&lang=ts"
export * from "./Vehicles.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QInput,QSpace,QBtn,QSeparator});
